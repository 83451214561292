// import axios
import axios from 'axios';

// import env var
import { API_IP, API_PROTOCOLE } from '@/env_var';

export const ws_sheme = window.location.protocol == 'https:' ? 'wss' : 'ws';

// axios instance
export const axios_instance = axios.create( {
  baseURL: `${ API_PROTOCOLE }://${ API_IP }/`,
  headers: {
    'Content-Type': 'application/json',
  },
} );
