// import classes
import { Depth } from '@/models/data_context/depth';
import { DateRange } from '@/models/data_context/date_range';

// import interfaces
import moment, { Moment, unitOfTime } from 'moment-timezone';

// import hooks
import store from '@/store';

export abstract class User {
  code: number;
  client: number;
  mail: string;
  username: string;
  isAdmin: number;
  can_export_data: boolean;
  refresh_token: string;
  access_token: string;
  refreshing_token: null | Promise<{
    refresh_token: string;
    access_token: string;
  }>;
  rights: Record<string, Array<number>>;
  granularities: Array<number>;
  removed_observations: Array<number> = [];
  registered: boolean | Moment;

  constructor(
    code: number,
    client: number,
    mail: string,
    username: string,
    isAdmin: number,
    can_export_data: boolean | false,
    refresh_token: string,
    access_token: string,
    rights: Record<string, Array<number>>,
    granularities: Array<number>,
    removed_observations: Array<number>,
    registered: boolean | string
  ) {
    this.code = code;
    this.client = client;
    this.mail = mail;
    this.username = username;
    this.isAdmin = isAdmin;
    this.can_export_data = can_export_data;
    this.refresh_token = refresh_token;
    this.access_token = access_token;
    this.refreshing_token = null;
    this.rights = rights;
    this.granularities = granularities;
    this.removed_observations = removed_observations;
    this.registered = typeof registered === 'string' ? moment.tz(registered, store.state.timezone) : registered;
  }
}

export class UserDepth extends User {
  depth: Record<string, Depth>;

  constructor(
    code: number,
    client: number,
    mail: string,
    username: string,
    isAdmin: number,
    can_export_data: boolean,
    refresh_token: string,
    access_token: string,
    rights: Record<string, Array<number>>,
    granularities: Array<number>,
    removed_observations: Array<number>,
    registered: boolean | string,
    depth: Record<string, { number: number; unit: string; }>
  ) {
    super(
      code,
      client,
      mail,
      username,
      isAdmin,
      can_export_data,
      refresh_token,
      access_token,
      rights,
      granularities,
      removed_observations,
      registered
    );

    this.depth = {};
    Object.keys(depth).forEach(key => {
      this.depth[key] = new Depth(
        depth[key].number,
        depth[key].unit as unitOfTime.DurationConstructor
      );
    });
  }
}

export class UserPeriod extends User {
  period: Record<string, DateRange>;

  constructor(
    code: number,
    client: number,
    mail: string,
    username: string,
    isAdmin: number,
    can_export_data: boolean,
    refresh_token: string,
    access_token: string,
    rights: Record<string, Array<number>>,
    granularities: Array<number>,
    removed_observations: Array<number>,
    registered: boolean | string,
    period: Record<string, { from: string; to: string; }>
  ) {
    super(
      code,
      client,
      mail,
      username,
      isAdmin,
      can_export_data,
      refresh_token,
      access_token,
      rights,
      granularities,
      removed_observations,
      registered
    );

    this.period = {};
    Object.keys(period).forEach(key => {
      this.period[key] = new DateRange(
        moment.tz(period[key].from, store.state.timezone),
        moment.tz(period[key].to, store.state.timezone)
      );
    });
  }
}

export interface user_i {
  code: number;
  client: number;
  mail: string;
  username: string;
  isAdmin: number;
  can_export_data: boolean;
  refresh_token: string;
  access_token: string;
  refreshing_token: null | Promise<{
    refresh_token: string;
    access_token: string;
  }>;
  rights: Record<string, Array<number>>;
  granularities: Array<number>;
  removed_observations: Array<number>;
  registered: boolean | string;
  depth?: Record<string, { number: number; unit: string; }>;
  period?: Record<string, { from: string; to: string; }>;
}
