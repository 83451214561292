import script from "./observation_selector_item.vue?vue&type=script&setup=true&lang=ts"
export * from "./observation_selector_item.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QCheckbox,QIcon,QMenu});
