// import interfaces
import moment, { Moment } from 'moment-timezone';

// import store
import store from '@/store';

export class DateRange {
  from: Moment;
  to: Moment;

  constructor(
    from: Moment = moment.tz(store.state.timezone).startOf('day').subtract(2, 'days'),
    to: Moment = moment.tz(store.state.timezone).endOf('day')
  ) {
    this.from = from.tz(store.state.timezone);
    this.to = to.tz(store.state.timezone);
  }

  toJson(): date_range_i {
    const sourceOffset = this.from.utcOffset();
    this.from.tz(store.state.timezone);
    this.to.tz(store.state.timezone);
    const offsetDifference = this.from.utcOffset() - sourceOffset;
    this.from.subtract(offsetDifference, 'minutes');
    this.to.subtract(offsetDifference, 'minutes');

    return {
      from: this.from.toISOString(),
      to: this.to.toISOString(),
    };
  }
}

export interface date_range_i {
  from: string;
  to: string;
}
