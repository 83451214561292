// import interfaces
import { widgets_i } from '@/models/dashboard/widgets';

// import classes
import { Widgets } from '@/models/dashboard/widgets';

export class Dashboard {
  code: number;
  label: string;
  stations: Array<number>;
  widgets: Widgets;
  sharepoint: string | null;

  constructor(
    code: number,
    label: string,
    stations: Array<number>,
    widgets: widgets_i = { sm: [], md: [], lg: [] },
    sharepoint: string | null = null
  ) {
    this.code = code;
    this.label = label;
    this.stations = stations;
    this.widgets = new Widgets(widgets.sm, widgets.md, widgets.lg);
    this.sharepoint = sharepoint;
  }

  toJson(): dashboard_i {
    return {
      code: this.code,
      label: this.label,
      stations: this.stations,
      widgets: this.widgets.toJson(),
    };
  }
}

export interface dashboard_i {
  code: number;
  label: string;
  stations: Array<number>;
  widgets: widgets_i;
}
