// import utils
// @ts-expect-error wait for update on @type/dygraphs (dygraphs utils compatible)
import { findPos, pageX, pageY } from 'dygraphs/src/dygraph-utils';

// import interface
import {
  dygraph_object,
  dygraph_context,
} from '@/utils/dygraph/dygraph_interface';

const distanceFromInterval = (
  x: number,
  left: number,
  right: number
): number => {
  if (x < left) return left - x;
  else if (x > right) return x - right;
  else return 0;
};

export const distanceFromChart = (
  event: TouchEvent,
  g: dygraph_object
): number => {
  const chartPos = findPos(g.canvas_);

  const box = {
    left: chartPos.x,
    right: chartPos.x + g.canvas_.offsetWidth,
    top: chartPos.y,
    bottom: chartPos.y + g.canvas_.offsetHeight,
  };

  const pt = {
    x: pageX(event),
    y: pageY(event),
  };

  const dx = distanceFromInterval(pt.x, box.left, box.right);
  const dy = distanceFromInterval(pt.y, box.top, box.bottom);

  return Math.max(dx, dy);
};

export const startTouch = (
  event: TouchEvent,
  g: dygraph_object,
  context: dygraph_context
): void => {
  event.preventDefault();

  if (event.touches.length > 1) context.startTimeForDoubleTapMs = null;

  const touches = [];
  for (let i = 0; i < event.touches.length; ++i) {
    const t = event.touches[i];

    touches.push({
      pageX: t.pageX,
      pageY: t.pageY,
      dataX: g.toDataXCoord(t.pageX),
      dataY: g.toDataYCoord(t.pageY),
    });
  }
  context.initialTouches = touches;

  if (touches.length === 1) {
    context.initialPinchCenter = touches[0];
    context.touchDirections = { x: true, y: true };
  } else if (touches.length >= 2) {
    context.initialPinchCenter = {
      pageX: 0.5 * (touches[0].pageX + touches[1].pageX),
      pageY: 0.5 * (touches[0].pageY + touches[1].pageY),
      dataX: 0.5 * (touches[0].dataX + touches[1].dataX),
      dataY: 0.5 * (touches[0].dataY + touches[1].dataY),
    };

    let initialAngle =
      (180 / Math.PI) *
      Math.atan2(
        context.initialPinchCenter.pageY - touches[0].pageY,
        touches[0].pageX - context.initialPinchCenter.pageX
      );
    initialAngle = Math.abs(initialAngle);

    if (initialAngle > 90) initialAngle = 90 - initialAngle;

    context.touchDirections = {
      x: initialAngle < 90 - 45 / 2,
      y: initialAngle > 45 / 2,
    };
  }

  context.initialRange = {
    x: g.xAxisRange(),
    y: g.yAxisRange(),
  };
};

export const moveTouch = (
  event: TouchEvent,
  g: dygraph_object,
  context: dygraph_context
): void => {
  context.startTimeForDoubleTapMs = null;
  const touches = [];

  for (let i = 0; i < event.touches.length; ++i) {
    const t = event.touches[i];
    touches.push({
      pageX: t.pageX,
      pageY: t.pageY,
    });
  }
  const initialTouches = context.initialTouches;

  let c_now;
  const c_init = context.initialPinchCenter;
  if (touches.length === 1) c_now = touches[0];
  else {
    c_now = {
      pageX: 0.5 * (touches[0].pageX + touches[1].pageX),
      pageY: 0.5 * (touches[0].pageY + touches[1].pageY),
    };
  }

  const swipe: Record<string, number> = {
    pageX: c_now.pageX - c_init.pageX,
    pageY: c_now.pageY - c_init.pageY,
  };
  const dataWidth = context.initialRange.x[1] - context.initialRange.x[0];
  const dataHeight = context.initialRange.y[0] - context.initialRange.y[1];

  swipe.dataX = (swipe.pageX / g.plotter_.area.w) * dataWidth;
  swipe.dataY = (swipe.pageY / g.plotter_.area.h) * dataHeight;

  let xScale = 0,
    yScale = 0;
  if (touches.length === 1) {
    xScale = 1.0;
    yScale = 1.0;
  } else if (touches.length >= 2) {
    const initHalfWidth = initialTouches[1].pageX - c_init.pageX;
    xScale = (touches[1].pageX - c_now.pageX) / initHalfWidth;
    const initHalfHeight = initialTouches[1].pageY - c_init.pageY;
    yScale = (touches[1].pageY - c_now.pageY) / initHalfHeight;
  }

  xScale = Math.min(8, Math.max(0.125, xScale));
  yScale = Math.min(8, Math.max(0.125, yScale));

  let didZoom = false;
  if (context.touchDirections.x) {
    g.dateWindow_ = [
      c_init.dataX -
      swipe.dataX +
      (context.initialRange.x[0] - c_init.dataX) / xScale,
      c_init.dataX -
      swipe.dataX +
      (context.initialRange.x[1] - c_init.dataX) / xScale,
    ];
    didZoom = true;
  }

  if (context.touchDirections.y) {
    for (let i = 0; i < 1; ++i) {
      const axis = g.axes_[i];
      axis.valueRange = [
        c_init.dataY -
        swipe.dataY +
        (context.initialRange.y[0] - c_init.dataY) / yScale,
        c_init.dataY -
        swipe.dataY +
        (context.initialRange.y[1] - c_init.dataY) / yScale,
      ];
      didZoom = true;
    }
  }

  g.drawGraph_(false);

  if (didZoom && touches.length > 1 && g.getFunctionOption('zoomCallback')) {
    const viewWindow = g.xAxisRange();
    g.getFunctionOption('zoomCallback').call(
      g,
      viewWindow[0],
      viewWindow[1],
      g.yAxisRanges()
    );
  }
};

export const endTouch = (
  event: TouchEvent,
  g: dygraph_object,
  context: dygraph_context
): void => {
  if (event.touches.length !== 0) startTouch(event, g, context);
  else if (event.changedTouches.length === 1) {
    const now = new Date().getTime();
    const t = event.changedTouches[0];

    if (
      context.startTimeForDoubleTapMs &&
      now - context.startTimeForDoubleTapMs < 500 &&
      context.doubleTapX &&
      Math.abs(context.doubleTapX - t.screenX) < 50 &&
      context.doubleTapY &&
      Math.abs(context.doubleTapY - t.screenY) < 50
    ) {
      g.resetZoom();
    } else {
      context.startTimeForDoubleTapMs = now;
      context.doubleTapX = t.screenX;
      context.doubleTapY = t.screenY;
    }
  }
};
