// import interfaces
import { unitOfTime } from 'moment-timezone';

export class Depth {
  number: number;
  unit: unitOfTime.DurationConstructor;

  constructor(number = 7, unit = 'days') {
    this.number = number;
    this.unit = unit as unitOfTime.DurationConstructor;
  }

  toJson(): depth_i {
    return {
      number: this.number,
      unit: this.unit as string,
    };
  }
}

export interface depth_i {
  number: number;
  unit: string;
}
