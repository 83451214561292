// vuex import
import { createStore } from 'vuex';

// router import
import router from '@/router';

// import axios instance
import { API_IP } from '@/env_var';
import { axios_instance, ws_sheme } from '@/connection';

// import classes
import { User, UserDepth, UserPeriod, user_i } from '@/models/auth/user';
import { ClientDepth, ClientPeriod, client_i } from '@/models/auth/client';
import { Station, station_i } from '@/models/context/station';
import { DataLogger, data_logger_i } from '@/models/context/data_logger';
import {
  Observation,
  ObservationWind,
  observation_i,
} from '@/models/context/observation';
import {
  ObservationSorter,
  observation_sorter_i,
} from '@/models/observation_sorter/observation_sorter';
import { Granularity, granularity_i } from '@/models/context/granularity';
import { Unit, unit_i } from '@/models/context/unit';
import { Notification } from '@/models/utils/notification';
import { LogCommunication, log_communication_i } from '@/models/log/log_communication';
import { LogTechnical } from '@/models/log/log_technical';
import { DataSingle } from '@/models/data/data_single';
import { DataMultipleGraphWidget } from '@/models/data/data_multiple_graph_widget';
import { ObservationValue, ObservationValueGraph } from '@/models/data_context/observation_value';
import { Dashboard } from '@/models/dashboard/dashboard';
import { Widget } from '@/models/dashboard/widget';
import { Widgets } from '@/models/dashboard/widgets';
import {
  WidgetParamLog,
  WidgetParamMultiple,
  WidgetParamSingle,
} from '@/models/dashboard/param';
import { Depth, depth_i } from '@/models/data_context/depth';
import { DateRange, date_range_i } from '@/models/data_context/date_range';

// import interface
import {
  store_i,
  reset_default_store,
  reset_default_pages,
} from '@/store/store_interface';

// import utils
import { getCookie, setCookie, removeCookie } from 'typescript-cookie';
import moment from 'moment-timezone';

// store
export default createStore<store_i>({
  // set state as default
  state: reset_default_store(),

  // state manipulation
  mutations: {
    reset(state) {
      Object.assign(state, reset_default_store());
    },

    set_regreshing_token(
      state,
      refreshing_token: null | Promise<{
        refresh_token: string;
        access_token: string;
      }>
    ) {
      state.user.refreshing_token = refreshing_token;
    },

    set_session_timeout(state, session_timeout: boolean) {
      state.session_timeout = session_timeout;
    },

    set_user(state, user: User) {
      state.user = user;
      state.pages = reset_default_pages();

      state.pages.push({ name: 'Export', icon: 'cloud_download' });

      if (user.isAdmin > 0) {
        state.pages.push({ name: 'Stations', icon: 'factory' });
        state.pages.push({ name: 'Users', icon: 'manage_accounts' });
      }
      if (user.isAdmin == 2)
        state.pages.push({ name: 'Clients', icon: 'supervisor_account' });
    },

    set_user_rights(
      state,
      user: {
        isAdmin: number;
        can_export_data: boolean;
        rights: Record<string, Array<number>>;
        granularities: Array<number>;
        depth: Record<string, depth_i>;
        period: Record<string, date_range_i>;
      }
    ) {
      state.user.isAdmin = user.isAdmin;
      state.user.can_export_data = user.can_export_data;
      state.user.rights = user.rights;
      state.user.granularities = user.granularities;
      if ('depth' in state.user)
        for (const key in (state.user as UserDepth).depth)
          if (key in user.depth)
            (state.user as UserDepth).depth[key] = new Depth(
              user.depth[key].number,
              user.depth[key].unit
            );
      if ('period' in state.user)
        for (const key in (state.user as UserPeriod).period)
          if (key in user.period)
            (state.user as UserPeriod).period[key] = new DateRange(
              moment(user.period[key].from),
              moment(user.period[key].to)
            );
    },

    set_tokens(state, tokens: { access_token: string; refresh_token: string; }) {
      state.user.refresh_token = tokens.refresh_token;
      state.user.access_token = tokens.access_token;
    },

    set_has_context(state, has_context: boolean) {
      state.has_context = has_context;
    },

    set_context(
      state,
      context: {
        stations: Array<Station>;
        data_loggers: Array<DataLogger>;
        observations: Array<Observation>;
        observation_sorters: Array<ObservationSorter>;
        granularities: Array<Granularity>;
        units: Array<Unit>;
      }
    ) {
      state.stations = context.stations;
      for (const station of state.stations)
        state.last_stations_communication[station.code] = new LogCommunication();

      state.data_loggers = context.data_loggers;
      state.observations = context.observations;
      state.observation_sorters = context.observation_sorters;
      state.granularities = context.granularities;
      state.units = context.units;
    },

    set_web_socket_open(state, web_socket_open: boolean) {
      state.web_socket_open = web_socket_open;
    },

    set_web_socket(state, web_socket: WebSocket) {
      state.web_socket = web_socket;
    },

    set_station_communication(state, log_comm: log_communication_i) {
      state.last_stations_communication[log_comm.st] = new LogCommunication(
        log_comm.st,
        log_comm.d,
        log_comm.s,
        log_comm.t,
        log_comm.i,
        log_comm.e,
        log_comm.u,
        log_comm.fd,
        log_comm.ld,
        log_comm.o,
        log_comm.err,
        state.timezone
      );
    },

    add_notification(state, log_comm: log_communication_i) {
      state.notifications.unshift(new Notification(
        log_comm.id,
        `Communication from "${state.stations.find(station => station.code === log_comm.st)?.name}" at ${moment.tz(log_comm.d, state.timezone).format('DD/MM/YYYY HH:mm')}`
      ));
    },

    delete_notification(state, notification_id: string) {
      state.notifications = state.notifications.filter(
        notification => notification.id !== notification_id
      );
    },

    clear_notifications(state) {
      state.notifications = [];
    },

    set_timezone(state, timezone: string) {
      state.timezone = timezone;
    },

    set_dashboard(state, dashboard: Dashboard) {
      state.dashboard = dashboard;

      state.widgets_id_counter = 0;
      for (let i = 0; i < state.dashboard.widgets.lg.length; ++i) {
        state.dashboard.widgets.lg[i].i = state.widgets_id_counter;
        state.dashboard.widgets.md[i].i = state.widgets_id_counter;
        state.dashboard.widgets.sm[i].i = state.widgets_id_counter;
        state.dashboard.widgets.lg[i].trigger_resize = false;
        state.dashboard.widgets.md[i].trigger_resize = false;
        state.dashboard.widgets.sm[i].trigger_resize = false;
        state.widgets_id_counter += 1;
      }
    },

    set_dashboard_context(
      state,
      payload: { code: number; label: string; stations: Array<number>; }
    ) {
      state.dashboard.code = payload.code;
      state.dashboard.label = payload.label;
      state.dashboard.stations = payload.stations;
    },

    set_dashboard_widgets(state, widgets: Widgets) {
      state.dashboard.widgets = widgets;
    },

    set_dashboard_change(state, changed: boolean) {
      state.dashboard_change = changed;
    },

    add_widget_dashboard(
      state,
      payload: { widget: Widget; dashboard_size: 'lg' | 'md' | 'sm'; }
    ) {
      state.dashboard.widgets[payload.dashboard_size].push(payload.widget);
      state.dashboard_change = true;
    },

    delete_widget_dashboard(state, id: number) {
      state.dashboard.widgets.lg = state.dashboard.widgets.lg.filter(
        widget => widget.i !== id
      );
      state.dashboard.widgets.md = state.dashboard.widgets.md.filter(
        widget => widget.i !== id
      );
      state.dashboard.widgets.sm = state.dashboard.widgets.sm.filter(
        widget => widget.i !== id
      );
      state.dashboard_change = true;
    },

    resize_widget_dashboard(
      state,
      payload: { id: number; dashboard_size: 'lg' | 'md' | 'sm'; }
    ) {
      const widget = state.dashboard.widgets[payload.dashboard_size].find(
        widget => widget.i === payload.id
      );
      if (widget) widget.trigger_resize = !widget.trigger_resize;
      state.dashboard_change = true;
    },

    save_widget_param(
      state,
      payload: {
        id: number;
        param: WidgetParamSingle | WidgetParamMultiple | WidgetParamLog;
        init: boolean;
      }
    ) {
      const widget_lg = state.dashboard.widgets.lg.find(
        widget => widget.i === payload.id
      );
      if (widget_lg) {
        widget_lg.init = true;
        widget_lg.param = payload.param;
      }

      const widget_md = state.dashboard.widgets.md.find(
        widget => widget.i === payload.id
      );
      if (widget_md) {
        widget_md.init = true;
        widget_md.param = payload.param;
      }

      const widget_sm = state.dashboard.widgets.sm.find(
        widget => widget.i === payload.id
      );
      if (widget_sm) {
        widget_sm.init = true;
        widget_sm.param = payload.param;
      }

      if (!payload.init) state.dashboard_change = true;
    },

    increase_widgets_id_counter(state) {
      state.widgets_id_counter += 1;
    },

    set_graphs_colors(state, colors: Array<string>) {
      state.graphs_colors = colors;
    },

    add_station_to_context(
      state,
      payload: { station: Station; data_logger: DataLogger; }
    ) {
      state.stations.push(payload.station);
      state.data_loggers.push(payload.data_logger);
      state.stations.sort((a, b) => a.name.localeCompare(b.name));
    },

    del_station_from_context(state, code: number) {
      state.stations = state.stations.filter(station => station.code !== code);
      state.data_loggers = state.data_loggers.filter(
        data_logger => data_logger.station !== code
      );
    },
  },

  // api request
  actions: {
    // ------------------------------- USER -------------------------------- //
    // auth
    async axios_setup({ dispatch, commit, state }) {
      axios_instance.interceptors.request.use(config => {
        if (config.headers === undefined) return config;
        config.headers['access-token'] = state.user.access_token;
        return config;
      });

      axios_instance.interceptors.response.use(
        response => {
          return response;
        },
        async error => {
          if (
            error.response &&
            error.response.status === 401 &&
            !error.config._retry
          ) {
            error.config._retry = true;
            try {
              commit(
                'set_regreshing_token',
                state.user.refreshing_token ?? dispatch('update_tokens')
              );
              await state.user.refreshing_token;
              commit('set_regreshing_token', null);
              return axios_instance(error.config);
            } catch (err) {
              return Promise.reject(err);
            }
          }
          return Promise.reject(error);
        }
      );
    },

    async register(_, { id, username, password }) {
      await axios_instance.post('api/register/', {
        id,
        username,
        password,
      });
    },

    async login({ dispatch, commit }, { mail, password, refresh_token }) {
      commit('set_session_timeout', false);

      const result = (
        await axios_instance.post(
          'api/login/',
          {
            mail: mail.trim().toLowerCase(),
            password,
          },
          { headers: { 'refresh-token': refresh_token } }
        )
      ).data;

      const user =
        'depth' in result
          ? new UserDepth(
            result.code,
            result.client,
            result.mail,
            result.username,
            result.isAdmin,
            result.can_export_data,
            result.refresh_token,
            result.access_token,
            result.rights,
            result.granularities,
            result.removed_observations,
            result.registered,
            result.depth
          )
          : new UserPeriod(
            result.code,
            result.client,
            result.mail,
            result.username,
            result.isAdmin,
            result.can_export_data,
            result.refresh_token,
            result.access_token,
            result.rights,
            result.granularities,
            result.removed_observations,
            result.registered,
            result.period
          );

      commit('set_user', user);

      setCookie('refresh_token', result.refresh_token, {
        expires: 1,
        sameSite: 'strict',
      });

      dispatch('axios_setup');
    },

    async get_self({ commit }) {
      const result = (await axios_instance.get('api/get_self/')).data;

      commit('set_user_rights', {
        isAdmin: result.isAdmin,
        can_export_data: result.can_export_data,
        rights: result.rights,
        granularities: result.granularities,
        depth: 'depth' in result ? result.depth : null,
        period: 'period' in result ? result.period : null,
      });
    },

    async update_tokens({ commit }) {
      try {
        const result = (
          await axios_instance.get('api/update_tokens/', {
            headers: {
              'refresh-token': getCookie('refresh_token'),
            },
          })
        ).data;

        setCookie('refresh_token', result.refresh_token, {
          expires: 1,
          sameSite: 'strict',
        });
        commit('set_tokens', result);
      } catch {
        removeCookie('refresh_token');
        router.push('/login').then(() => {
          commit('reset');
          commit('set_session_timeout', true);
        });
        throw new Error('Session timeout');
      }
    },

    async change_password(_, { old_password, new_password }) {
      await axios_instance.post('api/update_password/', {
        old_password,
        new_password,
      });
    },

    async update_username({ state }, { username, password }) {
      await axios_instance.post('api/update_username/', { username: username.trim().toLowerCase(), password });
      state.user.username = username.trim().toLowerCase();
    },

    // context
    async get_context({ commit }) {
      commit('set_has_context', false);

      const result = (await axios_instance.get('api/get_context/')).data;

      commit('set_context', {
        stations: result.stations.map(
          (station: station_i) =>
            new Station(
              station.code,
              station.name,
              [],
              [],
              station.query_observation,
              station.data_logger,
              station.coordinate
            )
        ),
        data_loggers: result.data_loggers.map(
          (data_logger: data_logger_i) =>
            new DataLogger(
              data_logger.code,
              data_logger.identifier,
              data_logger.hardware_aux_to_software_aux,
              data_logger.station
            )
        ),
        observations: result.observations.map((observation: observation_i) =>
          'height' in observation
            ? new ObservationWind(
              observation.code,
              observation.labels,
              observation.descriptions,
              observation.link_unit,
              observation.link_granularity,
              observation.histogram,
              observation.type,
              observation.height as number
            )
            : new Observation(
              observation.code,
              observation.labels,
              observation.descriptions,
              observation.link_unit,
              observation.link_granularity,
              observation.histogram,
              observation.type
            )
        ),
        observation_sorters: result.observation_sorters.map(
          (observation_sorter: observation_sorter_i) =>
            new ObservationSorter(
              observation_sorter.link_granularity,
              observation_sorter.observations
            )
        ),
        granularities: result.granularities.map(
          (granularity: granularity_i) =>
            new Granularity(
              granularity.code,
              granularity.label,
              granularity.description
            )
        ),
        units: result.units.map(
          (unit: unit_i) => new Unit(unit.code, unit.label, unit.description)
        ),
      });

      commit('set_has_context', true);
    },

    // websocket
    async init_web_socket({ state, commit }) {
      const web_socket = new WebSocket(
        ws_sheme + '://' + API_IP + '/pulsoweb_ws'
      );

      web_socket.onopen = (): void => {
        commit('set_web_socket_open', true);
      };

      web_socket.onclose = (): void => {
        commit('set_web_socket_open', false);
      };

      web_socket.onerror = (): void => {
        commit('set_web_socket_open', false);
        removeCookie('refresh_token');
        router.push('/login').then(() => {
          commit('reset');
          commit('set_session_timeout', true);
        });
      };

      const handle_communication = async (res: MessageEvent): Promise<void> => {
        const log_comm = JSON.parse(res.data);

        commit('set_station_communication', log_comm);
        commit('add_notification', log_comm);
      };

      web_socket.onmessage = async (res): Promise<void> => {
        handle_communication(res);
      };

      commit('set_web_socket', web_socket);

      while (!state.web_socket_open || state.web_socket === null) {
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      state.web_socket.send(
        JSON.stringify({
          access_token: state.user.access_token,
        })
      );
    },

    async update_web_socket_requirement({ state }, { add, rmv }) {
      if (!state.web_socket_open || state.web_socket === null) return;
      state.web_socket.send(
        JSON.stringify({
          access_token: state.user.access_token,
          update_station: {
            add,
            rmv
          }
        })
      );
    },

    // data
    async init_value_widget({ state }, { observation, station, dashboard_mode }) {
      try {
        const data = (await axios_instance.post('api/init_value_widget/', {
          observation, station,
          dashboard_mode: ['live', 'last_hour', 'last_synop'].includes(dashboard_mode) ? dashboard_mode : moment.tz(dashboard_mode, state.timezone).toISOString(),
        })).data;
        return new DataSingle(state.timezone, data.value, data.date, observation.code);
      } catch {
        return new DataSingle(state.timezone);
      }
    },

    async init_graph_widget({ state }, { observations, stations, dashboard_mode, depth, }) {
      try {
        const multiple_data = (
          await axios_instance.post('api/init_graph_widget/', {
            stations,
            observations: observations.map((observation: ObservationValueGraph) =>
              observation.toCompactedJson()
            ),
            timezone: state.timezone,
            dashboard_mode: ['live', 'last_hour', 'last_synop'].includes(dashboard_mode) ? dashboard_mode : moment.tz(dashboard_mode, state.timezone).toISOString(),
            depth: depth.toJson()
          })
        ).data;
        return new DataMultipleGraphWidget(
          state.timezone,
          multiple_data.data,
          multiple_data.date_range
        );
      } catch {
        return new DataMultipleGraphWidget(
          state.timezone,
          [Array(stations.length * observations.length + 1).fill(0)]
        );
      }
    },

    async init_graph({ state }, { stations, observations, date_range }) {
      try {
        const data = (
          await axios_instance.post('api/init_graph/', {
            stations,
            observations: observations.map((observation: ObservationValueGraph) =>
              observation.toCompactedJson()
            ),
            timezone: state.timezone,
            date_range: date_range.toJson(),
          })
        ).data;

        for (const line of data)
          line[0] = new Date(line[0] as string);

        return data;
      } catch {
        return [Array(stations.length * observations.length + 1).fill(0)];
      }
    },

    async init_table({ state }, { stations, observations, date_range }) {
      try {
        const data = (
          await axios_instance.post('api/init_table/', {
            stations,
            observations: observations.map((observation: ObservationValue) =>
              observation.toJson()
            ),
            timezone: state.timezone,
            date_range: date_range.toJson()
          })
        ).data;
        for (const line of data)
          line["date"] = moment(line["date"]).tz(state.timezone);

        return data;
      } catch {
        return [];
      }
    },

    // get data to preview
    async init_preview_data({ state }, { stations, observations, date_range, limit_row, configurations }) {
      try {
        const data = (
          await axios_instance.post('api/preview_data/', {
            stations,
            observations: observations.map((observation: ObservationValue) =>
              observation.toJson()
            ),
            timezone: state.timezone,
            date_range: date_range.toJson(),
            configuration: configurations,
            limit_rows: limit_row ?? 0,
          })
        ).data;
        return data;
      } catch {
        return [];
      }
    },


    async export_data({ state }, { stations, observations, date_range, configuration, limit_rows }) {
      try {
        const response = (
          await axios_instance.post('api/export_data/', {
            stations,
            observations: observations.map((observation: ObservationValue) =>
              observation.toJson()
            ),
            date_range: date_range.toJson(),
            configuration: configuration,
            limit_rows: limit_rows ?? 0,
          }, { responseType: 'blob' })
        );

        const date_from = date_range.from.toISOString().slice(0, 16);
        const date_to = date_range.to.toISOString().slice(0, 16);
        const granilarity = observations[0]['code'].toString().slice(0, 2);
        let prefix = (stations.length === 1) ? state.stations.find(station => station.code === stations[0])?.name : 'STATIONS';
        prefix += '_' + state.granularities.find(granularity => granularity.code === parseInt(granilarity))?.label.toUpperCase();

        let filename = `${prefix}-${date_from}-to-${date_to}.`;

        if (configuration.compression !== 'none') {
          filename += configuration.compression.toLowerCase();
        } else {
          filename += configuration.format.toLowerCase()
        }

        const content = URL.createObjectURL(response.data);
        saveFile(content, filename);

        state.snackbar = {
          show: true,
          text: 'Your data has been successfully exported.',
          color: 'success'
        };

      } catch (error) {
        state.snackbar = {
          show: true,
          text: 'No data found to export according to these paramaters.',
          color: 'error'
        };
      }
    },

    // log data
    async init_comm_log({ state }, { stations, dashboard_mode, depth }) {
      try {
        const logs = (
          await axios_instance.post('api/init_comm_log/', {
            stations,
            dashboard_mode: ['live', 'last_hour', 'last_synop'].includes(dashboard_mode) ? dashboard_mode : moment.tz(dashboard_mode, state.timezone).toISOString(),
            depth: depth.toJson()
          })
        ).data;

        const corrected_logs: Array<LogCommunication> = [];

        for (const log of logs)
          corrected_logs.push(new LogCommunication(
            log.st,
            log.d,
            log.s,
            log.t,
            log.i,
            log.e,
            log.u,
            log.fd,
            log.ld,
            log.o,
            log.err,
            state.timezone
          ));

        return corrected_logs;
      } catch {
        return [];
      }
    },

    async update_comm_log({ state }, { stations, date_range }) {
      try {
        const logs = (
          await axios_instance.post('api/update_comm_log/', {
            stations,
            date_range: date_range.toJson()
          })
        ).data;

        const corrected_logs: Array<LogCommunication> = [];

        for (const log of logs)
          corrected_logs.push(new LogCommunication(
            log.st,
            log.d,
            log.s,
            log.t,
            log.i,
            log.e,
            log.u,
            log.fd,
            log.ld,
            log.o,
            log.err,
            state.timezone
          ));

        return corrected_logs;
      } catch {
        return [];
      }
    },

    async init_tech_log({ state }, { stations, cat, us_cat, date_range }) {
      try {
        const logs = (
          await axios_instance.post('api/init_maintenance_log/', {
            stations,
            cat,
            us_cat,
            date_range: date_range.toJson(),
          })
        ).data;

        const corrected_logs: Array<LogTechnical> = [];
        for (const log of logs)
          corrected_logs.push(new LogTechnical(
            log.st,
            log.d,
            log.grav,
            log.task,
            log.cat,
            log.msg,
            log.type,
            state.timezone
          ));

        return corrected_logs;
      } catch {
        return [];
      }
    },

    // dashboard management
    async create_dashboard({ state }, { stations, label }) {
      const dashboard = (
        await axios_instance.post('api/create_dashboard/', {
          user: state.user.code,
          label,
          stations,
        })
      ).data;
      return new Dashboard(dashboard.code, label, stations);
    },

    async get_dashboard_context(_, station) {
      const dashboards_context = (
        await axios_instance.post('api/get_dashboard_context/', { station })
      ).data;
      return dashboards_context.map(
        (dashboard: { code: number; label: string; stations: Array<number>; }) =>
          new Dashboard(dashboard.code, dashboard.label, dashboard.stations)
      );
    },

    async get_dashboard({ commit }, code) {
      const dashboard = (
        await axios_instance.post('api/get_dashboard/', { code })
      ).data;
      commit(
        'set_dashboard',
        new Dashboard(
          dashboard.code,
          dashboard.label,
          dashboard.stations,
          dashboard.widgets,
          dashboard.sharepoint
        )
      );
    },

    async update_dashboard({ state }) {
      await axios_instance.post(
        'api/update_dashboard/',
        state.dashboard.toJson()
      );
    },

    async share_dashboard({ state }, code) {
      const res = (await axios_instance.post('api/share_dashboard/', { code }))
        .data;
      state.dashboard.sharepoint = res['sharepoint'];
    },

    async import_dashboard(_, sharepoint) {
      await axios_instance.post('api/get_shared_dasboard/', { sharepoint });
    },

    async delete_dashboard(_, code) {
      await axios_instance.post('api/delete_dashboard/', { code });
    },

    // client for export data
    async get_client_by_code() {
      try {
        const client = (await axios_instance.get('api/get_client/')).data;
        return client;
      } catch (error) {
        console.error('Error fetching client:', error);
        throw error;
      }
    },

    // ------------------------------- ADMIN ------------------------------- //
    // stations management
    async create_station(
      _,
      {
        name,
        identifier,
        latitude,
        longitude,
        altitude,
        aero_altitude,
        wind_aux0,
        wind_aux1,
        wind_aux2,
      }
    ) {
      const request_param: {
        name: string;
        identifier: string;
        coordinate: {
          latitude: number;
          longitude: number;
          altitude: number;
          aero_altitude?: number;
        };
        wind_aux0?: number;
        wind_aux1?: number;
        wind_aux2?: number;
      } = {
        name,
        identifier,
        coordinate: {
          latitude,
          longitude,
          altitude,
        },
      };

      if (aero_altitude !== null)
        request_param['coordinate']['aero_altitude'] = aero_altitude;
      if (wind_aux0 !== null) request_param['wind_aux0'] = wind_aux0;
      if (wind_aux1 !== null) request_param['wind_aux1'] = wind_aux1;
      if (wind_aux2 !== null) request_param['wind_aux2'] = wind_aux2;

      const res = (
        await axios_instance.post('api_admin/create_station/', request_param)
      ).data;
      if ('code' in res) return res.message;

      return {
        station: new Station(
          res.station.code,
          name,
          [],
          [],
          [],
          res.code,
          res.station.coordinate
        ),
        data_logger: new DataLogger(
          res.data_logger.code,
          identifier,
          res.data_logger.hardware_aux_to_software_aux,
          res.station.code,
          res.data_logger.config_aux0_wind,
          res.data_logger.config_aux1_wind,
          res.data_logger.config_aux2_wind
        ),
      };
    },

    async get_all_stations() {
      const res = (await axios_instance.get('api_admin/get_stations/')).data;
      return {
        stations: res.station.map(
          (station: station_i) =>
            new Station(
              station.code,
              station.name,
              station.exist_observation,
              station.import_observation,
              station.query_observation,
              station.data_logger,
              station.coordinate
            )
        ),
        data_loggers: res.data_logger.map(
          (data_logger: data_logger_i) =>
            new DataLogger(
              data_logger.code,
              data_logger.identifier,
              data_logger.hardware_aux_to_software_aux,
              data_logger.station,
              data_logger.config_aux0_wind,
              data_logger.config_aux1_wind,
              data_logger.config_aux2_wind
            )
        ),
      };
    },

    async get_deleted_stations() {
      const res = (
        await axios_instance.get('api_admin/get_soft_deleted_station/')
      ).data;
      return {
        stations: res.station.map(
          (station: station_i) =>
            new Station(
              station.code,
              station.name,
              station.exist_observation,
              station.import_observation,
              station.query_observation,
              station.data_logger,
              station.coordinate
            )
        ),
        data_loggers: res.data_logger.map(
          (data_logger: data_logger_i) =>
            new DataLogger(
              data_logger.code,
              data_logger.identifier,
              data_logger.hardware_aux_to_software_aux,
              data_logger.station,
              data_logger.config_aux0_wind,
              data_logger.config_aux1_wind,
              data_logger.config_aux2_wind
            )
        ),
      };
    },

    async update_station(
      _,
      {
        code,
        name,
        identifier,
        latitude,
        longitude,
        altitude,
        aero_altitude,
        import_observation,
        query_observation,
        wind_aux0,
        wind_aux1,
        wind_aux2,
      }
    ) {
      const request_param: {
        code: number;
        name: string;
        identifier: string;
        coordinate: {
          latitude: number;
          longitude: number;
          altitude: number;
          aero_altitude?: number;
        };
        import_observation: Array<number>;
        query_observation: Array<number>;
        wind_aux0?: number;
        wind_aux1?: number;
        wind_aux2?: number;
      } = {
        code,
        name,
        identifier,
        import_observation,
        query_observation,
        coordinate: {
          latitude,
          longitude,
          altitude,
        },
      };

      if (aero_altitude !== null)
        request_param['coordinate']['aero_altitude'] = aero_altitude;
      if (wind_aux0 !== null) request_param['wind_aux0'] = wind_aux0;
      if (wind_aux1 !== null) request_param['wind_aux1'] = wind_aux1;
      if (wind_aux2 !== null) request_param['wind_aux2'] = wind_aux2;

      const res = (
        await axios_instance.post('api_admin/update_station/', request_param)
      ).data;
      return {
        station: new Station(
          res.station.code,
          res.station.name,
          res.station.exist_observation,
          res.station.import_observation,
          res.station.query_observation,
          res.station.data_logger,
          res.station.coordinate
        ),
        data_logger: new DataLogger(
          res.data_logger.code,
          res.data_logger.identifier,
          res.data_logger.hardware_aux_to_software_aux,
          res.data_logger.station,
          res.data_logger.config_aux0_wind,
          res.data_logger.config_aux1_wind,
          res.data_logger.config_aux2_wind
        ),
      };
    },

    async recover_station(_, code) {
      await axios_instance.post('api_admin/recover_station/', { code });
    },

    async soft_delete_station(_, code) {
      await axios_instance.post('api_admin/soft_delete_station/', { code });
    },

    async delete_station(_, code) {
      await axios_instance.post('api_admin/delete_station/', { code });
    },

    // users management
    async create_user(_, { has_mail, client, mail, password, isAdmin, can_export_data }) {
      const res = (
        await axios_instance.post('api_admin/create_user/', {
          has_mail,
          client,
          mail: mail.trim().toLowerCase(),
          password,
          isAdmin: isAdmin ? 1 : 0,
          can_export_data,
        })
      ).data;
      if ('message' in res) return res.message;

      return 'depth' in res
        ? new UserDepth(
          res.code,
          res.client,
          res.mail,
          res.username,
          res.isAdmin,
          res.can_export_data,
          '',
          '',
          res.rights,
          res.granularities,
          res.removed_observations,
          res.registered,
          res.depth
        )
        : new UserPeriod(
          res.code,
          res.client,
          res.mail,
          res.username,
          res.isAdmin,
          res.can_export_data,
          '',
          '',
          res.rights,
          res.granularities,
          res.removed_observations,
          res.registered,
          res.period
        );
    },

    async resend_registration_mail(_, code) {
      await axios_instance.post('api_admin/resend_registration_mail/', { code });
    },

    async get_all_users() {
      const res = (await axios_instance.get('api_admin/get_users/')).data;

      return res.map((user: user_i) =>
        'depth' in user
          ? new UserDepth(
            user.code,
            user.client,
            user.mail,
            user.username,
            user.isAdmin,
            user.can_export_data,
            '',
            '',
            user.rights,
            user.granularities,
            user.removed_observations,
            user.registered,
            user.depth as Record<string, depth_i>
          )
          : new UserPeriod(
            user.code,
            user.client,
            user.mail,
            user.username,
            user.isAdmin,
            user.can_export_data,
            '',
            '',
            user.rights,
            user.granularities,
            user.removed_observations,
            user.registered,
            user.period as Record<string, date_range_i>
          )
      );
    },

    async update_user(
      _,
      { code, isAdmin, can_export_data, rights, granularities, removed_observations, depth, period }
    ) {
      const request_param: {
        code: number;
        isAdmin: number;
        can_export_data: boolean;
        rights: Record<number, Array<number>>;
        granularities: Array<number>;
        removed_observations: Array<number>;
        depth?: Record<number, Depth>;
        period?: Record<number, DateRange>;
      } = {
        code,
        isAdmin,
        can_export_data,
        rights,
        granularities,
        removed_observations,
      };

      if (depth !== null) request_param['depth'] = depth;
      if (period !== null) request_param['period'] = period;

      const res = (
        await axios_instance.post('api_admin/update_user/', request_param)
      ).data;

      return 'depth' in res
        ? new UserDepth(
          res.code,
          res.client,
          res.mail,
          res.username,
          res.isAdmin,
          res.can_export_data,
          '',
          '',
          res.rights,
          res.granularities,
          res.removed_observations,
          res.registered,
          res.depth
        )
        : new UserPeriod(
          res.code,
          res.client,
          res.mail,
          res.username,
          res.isAdmin,
          res.can_export_data,
          '',
          '',
          res.rights,
          res.granularities,
          res.removed_observations,
          res.registered,
          res.period
        );
    },

    async update_user_password(_, { code, password, new_user_password }) {
      await axios_instance.post('api_admin/update_user_password/', {
        code,
        password,
        new_user_password
      });
    },

    async update_user_mail(_, { code, password, new_user_mail }) {
      await axios_instance.post('api_admin/update_user_mail/', {
        code,
        password,
        new_user_mail: new_user_mail.trim().toLowerCase()
      });
    },

    async delete_user(_, code) {
      await axios_instance.post('api_admin/delete_user/', { code });
    },

    // client management
    async get_client() {
      const client = (await axios_instance.get('api_admin/get_client/')).data;
      return 'depth' in client
        ? new ClientDepth(
          client.code,
          client.name,
          client.isAdmin,
          client.can_export_data,
          client.rights,
          client.granularities,
          client.removed_observations,
          client.depth
        )
        : new ClientPeriod(
          client.code,
          client.name,
          client.isAdmin,
          client.can_export_data,
          client.rights,
          client.granularities,
          client.removed_observations,
          client.period
        );
    },

    // ------------------------------- S-ADMIN ----------------------------- //
    // clients management
    async create_client(_, { name, can_export_data, depth }) {
      const res = (
        await axios_instance.post('api_admin/create_client/', { name, can_export_data, depth })
      ).data;
      if ('message' in res) return res.message;

      return 'depth' in res
        ? new ClientDepth(
          res.code,
          res.name,
          res.isAdmin,
          res.can_export_data,
          res.rights,
          res.granularities,
          res.removed_observations,
          res.depth
        )
        : new ClientPeriod(
          res.code,
          res.name,
          res.isAdmin,
          res.can_export_data,
          res.rights,
          res.granularities,
          res.removed_observations,
          res.period
        );
    },

    async get_all_clients() {
      const res = (await axios_instance.get('api_admin/get_clients/')).data;
      return res.map((client: client_i) =>
        'depth' in client
          ? new ClientDepth(
            client.code,
            client.name,
            client.isAdmin,
            client.can_export_data,
            client.rights,
            client.granularities,
            client.removed_observations,
            client.depth as Record<string, depth_i>
          )
          : new ClientPeriod(
            client.code,
            client.name,
            client.isAdmin,
            client.can_export_data,
            client.rights,
            client.granularities,
            client.removed_observations,
            client.period as Record<string, date_range_i>
          )
      );
    },

    async update_client(
      _,
      { code, name, can_export_data, rights, granularities, removed_observations, depth, period }
    ) {
      const request_param: {
        code: number;
        name: string;
        can_export_data: boolean;
        rights: Record<string, Array<number>>;
        granularities: Array<number>;
        removed_observations: Array<number>;
        depth?: Record<number, Depth>;
        period?: Record<number, DateRange>;
      } = {
        code,
        name,
        can_export_data,
        rights,
        granularities,
        removed_observations,
      };

      if (depth !== null) request_param['depth'] = depth;
      if (period !== null) request_param['period'] = period;

      const res = (
        await axios_instance.post('api_admin/update_client/', request_param)
      ).data;

      return 'depth' in res
        ? new ClientDepth(
          res.code,
          res.name,
          res.isAdmin,
          res.can_export_data,
          res.rights,
          res.granularities,
          res.removed_observations,
          res.depth
        )
        : new ClientPeriod(
          res.code,
          res.name,
          res.isAdmin,
          res.can_export_data,
          res.rights,
          res.granularities,
          res.removed_observations,
          res.period
        );
    },

    async delete_client(_, code) {
      await axios_instance.post('api_admin/delete_client/', { code });
    },
  },
});

function saveFile(url: string, filename: string) {
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "file-name";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

