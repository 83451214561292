import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import register_page from '@/views/register_page.vue';
import login_page from '@/views/login_page.vue';
import home_page from '@/views/home_page.vue';
import user_profile_page from '@/views/user_profile_page.vue';
import dashboard_page from '@/views/dashboard_page.vue';
import graphs_page from '@/views/graphs_page.vue';
import table_page from '@/views/table_page.vue';
import maintenance_page from '@/views/maintenance_page.vue';
import stations_management_page from '@/views/stations_management_page.vue';
import users_management_page from '@/views/users_management_page.vue';
import clients_management_page from '@/views/clients_management_page.vue';
import export_page from '@/views/export_page.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/register/:registration_code',
    name: 'register',
    component: register_page,
  },
  {
    path: '/login',
    name: 'login',
    component: login_page,
  },
  {
    path: '/',
    name: 'home',
    component: home_page,
  },
  {
    path: '/profile',
    name: 'profile',
    component: user_profile_page,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard_page,
  },
  {
    path: '/graphs',
    name: 'graphs',
    component: graphs_page,
  },
  {
    path: '/table',
    name: 'table',
    component: table_page,
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: maintenance_page,
  },
  {
    path: '/stations',
    name: 'stations',
    component: stations_management_page,
  },
  {
    path: '/users',
    name: 'users',
    component: users_management_page,
  },
  {
    path: '/clients',
    name: 'clients',
    component: clients_management_page,
  },
  {
    path: '/export',
    name: 'export',
    component: export_page,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
