// import classes
import {
  Widget,
  WidgetLog,
  WidgetMultiple,
  WidgetSingle,
  widget_i,
} from '@/models/dashboard/widget';

export class Widgets {
  sm: Array<Widget>;
  md: Array<Widget>;
  lg: Array<Widget>;

  constructor(
    sm: Array<widget_i> = [],
    md: Array<widget_i> = [],
    lg: Array<widget_i> = []
  ) {
    this.sm = sm
      .map((widget: widget_i) => this.convertToWidget(widget))
      .filter(widget => widget !== null) as Array<Widget>;
    this.md = md
      .map((widget: widget_i) => this.convertToWidget(widget))
      .filter(widget => widget !== null) as Array<Widget>;
    this.lg = lg
      .map((widget: widget_i) => this.convertToWidget(widget))
      .filter(widget => widget !== null) as Array<Widget>;
  }

  convertToWidget(widget: widget_i): Widget | null {
    switch (widget.type) {
      case 'Value':
        return new WidgetSingle(
          widget.i,
          widget.w,
          widget.min_width,
          widget.h,
          widget.min_height,
          widget.x,
          widget.y,
          widget.type,
          widget.trigger_resize,
          widget.param
        );
      case 'Graph':
        return new WidgetMultiple(
          widget.i,
          widget.w,
          widget.min_width,
          widget.h,
          widget.min_height,
          widget.x,
          widget.y,
          widget.type,
          widget.trigger_resize,
          widget.param
        );
      case 'Graphic':
        return new WidgetMultiple(
          widget.i,
          widget.w,
          widget.min_width,
          widget.h,
          widget.min_height,
          widget.x,
          widget.y,
          widget.type,
          widget.trigger_resize,
          widget.param
        );
      case 'Communication':
        return new WidgetLog(
          widget.i,
          widget.w,
          widget.min_width,
          widget.h,
          widget.min_height,
          widget.x,
          widget.y,
          widget.type,
          widget.trigger_resize,
          widget.param
        );
      default:
        return null;
    }
  }

  toJson(): widgets_i {
    return {
      sm: this.sm.map(widget => widget.toJson()),
      md: this.md.map(widget => widget.toJson()),
      lg: this.lg.map(widget => widget.toJson()),
    };
  }
}

export interface widgets_i {
  sm: Array<widget_i>;
  md: Array<widget_i>;
  lg: Array<widget_i>;
}
