export class Unit {
  code: number;
  label: string;
  description: string;

  constructor(code: number, label: string, description: string) {
    this.code = code;
    this.label = label;
    this.description = description;
  }
}

export interface unit_i {
  code: number;
  label: string;
  description: string;
}
