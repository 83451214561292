export class Observation {
  code: number;
  labels: Record<string, string>;
  descriptions: Record<string, string>;
  link_unit: number;
  link_granularity: number;
  histogram: boolean;
  type: string;

  constructor(
    code: number,
    labels: Record<string, string>,
    descriptions: Record<string, string>,
    link_unit: number,
    link_granularity: number,
    histogram: boolean,
    type: string
  ) {
    this.code = code;
    this.labels = labels;
    this.descriptions = descriptions;
    this.link_unit = link_unit;
    this.link_granularity = link_granularity;
    this.histogram = histogram;
    this.type = type;
  }
}

export class ObservationWind extends Observation {
  height: number;

  constructor(
    code: number,
    labels: Record<string, string>,
    descriptions: Record<string, string>,
    link_unit: number,
    link_granularity: number,
    histogram: boolean,
    type: string,
    height: number
  ) {
    super(
      code,
      labels,
      descriptions,
      link_unit,
      link_granularity,
      histogram,
      type
    );

    this.height = height;
  }
}

export interface observation_i {
  code: number;
  labels: Record<string, string>;
  descriptions: Record<string, string>;
  link_unit: number;
  link_granularity: number;
  histogram: boolean;
  type: string;
  height?: number;
}
