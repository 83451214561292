import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Quasar from 'quasar/src/vue-plugin.js';;

// @ts-expect-error wait for update on vue-grid-layout (update vue-grid-layout types)
import VueGridLayout from 'vue-grid-layout';

// @ts-expect-error wait for update on quasar (update quasarUserOptions types)
import quasarUserOptions from './quasar-user-options.js';

createApp( App )
  .use( Quasar, quasarUserOptions )
  .use( store )
  .use( router )
  .use( VueGridLayout )
  .mount( '#app' );
