import script from "./graph_widget.vue?vue&type=script&setup=true&lang=ts"
export * from "./graph_widget.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QChip from 'quasar/src/components/chip/QChip.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QChip,QTooltip,QInnerLoading});
