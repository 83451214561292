// import classes
import { DateRange } from '@/models/data_context/date_range';

// import interfaces
import moment from 'moment-timezone';

export class DataMultipleGraphWidget {
  data: Array<Array<Date | string | number | null>>;
  date_range: DateRange;

  constructor(
    timezone = 'UTC',
    data: Array<Array<string | number | null | string>> = [],
    date_range: { from: string; to: string; } = {
      from: moment.tz(timezone).toISOString(),
      to: moment.tz(timezone).toISOString(),
    },
  ) {
    this.date_range = new DateRange(
      moment.tz(date_range.from, timezone),
      moment.tz(date_range.to, timezone)
    );

    this.data = [];
    for (const line of data)
      this.data.push([new Date(line[0] as string), ...line.slice(1) as Array<string | number | null>]);
  }
}
