// for prod
export const API_IP = 'app.pulsonic.com';
export const API_PROTOCOLE = 'https';

// for dev
// export const API_IP = 'localhost:8000';
// export const API_PROTOCOLE = 'http';

export const DEFAULT_GRANULARITY = 32;
export const GRANULARITIES = {
  MIN: 22,
  MIN6: 27,
  MIN10: 28,
  MIN30: 31,
  HOUR: 32,
  HOUR3: 34,
  DAY: 38,
};
