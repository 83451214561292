// import interfaces
import { widget_param_i } from '@/models/dashboard/param';
import { depth_i } from '@/models/data_context/depth';
import { observation_value_i } from '@/models/data_context/observation_value';

// import classes
import {
  WidgetParamSingle,
  WidgetParamMultiple,
  WidgetParamLog,
} from '@/models/dashboard/param';

export abstract class Widget {
  i: number;
  w: number;
  min_width: number;
  h: number;
  min_height: number;
  x: number;
  y: number;
  type: string;
  trigger_resize: boolean;
  init: boolean;
  abstract param: WidgetParamSingle | WidgetParamMultiple | WidgetParamLog;

  constructor(
    i: number,
    w: number,
    min_width: number,
    h: number,
    min_height: number,
    x: number,
    y: number,
    type: string,
    trigger_resize: boolean,
    init = false
  ) {
    this.i = i;
    this.w = w;
    this.min_width = min_width;
    this.h = h;
    this.min_height = min_height;
    this.x = x;
    this.y = y;
    this.type = type;
    this.trigger_resize = trigger_resize;
    this.init = init;
  }

  abstract toJson(): widget_i;
}

export class WidgetSingle extends Widget {
  param: WidgetParamSingle;

  constructor(
    i: number,
    w: number,
    min_width: number,
    h: number,
    min_height: number,
    x: number,
    y: number,
    type: string,
    trigger_resize: boolean,
    param: widget_param_i
  ) {
    super(i, w, min_width, h, min_height, x, y, type, trigger_resize);

    this.param = new WidgetParamSingle(
      param.label,
      'station_label' in param ? (param.station_label as boolean) : false,
      param.show_date,
      param.show_station,
      param.show_obs,
      param.station as number,
      param.granularity as number,
      param.observation as observation_value_i
    );
  }

  toJson(): widget_i {
    return {
      i: this.i,
      w: this.w,
      min_width: this.min_width,
      h: this.h,
      min_height: this.min_height,
      x: this.x,
      y: this.y,
      type: this.type,
      trigger_resize: this.trigger_resize,
      param: this.param.toJson(),
    };
  }
}

export class WidgetMultiple extends Widget {
  param: WidgetParamMultiple;

  constructor(
    i: number,
    w: number,
    min_width: number,
    h: number,
    min_height: number,
    x: number,
    y: number,
    type: string,
    trigger_resize: boolean,
    param: widget_param_i
  ) {
    super(i, w, min_width, h, min_height, x, y, type, trigger_resize);

    this.param = new WidgetParamMultiple(
      param.label,
      'station_label' in param ? (param.station_label as boolean) : false,
      param.show_date,
      param.show_station,
      param.show_obs,
      param.station as Array<number>,
      param.granularity as number,
      param.observation as Array<observation_value_i>,
      param.depth as depth_i
    );
  }

  toJson(): widget_i {
    return {
      i: this.i,
      w: this.w,
      min_width: this.min_width,
      h: this.h,
      min_height: this.min_height,
      x: this.x,
      y: this.y,
      type: this.type,
      trigger_resize: this.trigger_resize,
      param: this.param.toJson(),
    };
  }
}

export class WidgetLog extends Widget {
  param: WidgetParamLog;

  constructor(
    i: number,
    w: number,
    min_width: number,
    h: number,
    min_height: number,
    x: number,
    y: number,
    type: string,
    trigger_resize: boolean,
    param: widget_param_i
  ) {
    super(i, w, min_width, h, min_height, x, y, type, trigger_resize);

    this.param = new WidgetParamLog(
      param.label,
      'station_label' in param ? (param.station_label as boolean) : false,
      param.show_date,
      param.show_station,
      param.show_obs,
      param.station as Array<number>,
      param.depth as depth_i
    );
  }

  toJson(): widget_i {
    return {
      i: this.i,
      w: this.w,
      min_width: this.min_width,
      h: this.h,
      min_height: this.min_height,
      x: this.x,
      y: this.y,
      type: this.type,
      trigger_resize: this.trigger_resize,
      param: this.param.toJson(),
    };
  }
}

export interface widget_i {
  i: number;
  w: number;
  min_width: number;
  h: number;
  min_height: number;
  x: number;
  y: number;
  type: string;
  trigger_resize: boolean;
  param: widget_param_i;
}
