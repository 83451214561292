// import utils
import _ from 'lodash';

// @ts-expect-error wait for update on @type/dygraphs (dygraphs utils compatible)
import { toRGB_ } from 'dygraphs/src/dygraph-utils.js';

// import classes
import { Observation } from '@/models/context/observation';
import { Unit } from '@/models/context/unit';

// import interface
import { dygraphs } from 'dygraphs';
import { plotter_event } from '@/utils/dygraph/dygraph_interface';

// import store
import store from '@/store';

function darkenColor(colorStr: string | null | undefined) {
  const color = toRGB_(colorStr);
  color.r = Math.floor((255 + color.r) / 2);
  color.g = Math.floor((255 + color.g) / 2);
  color.b = Math.floor((255 + color.b) / 2);
  return 'rgb(' + color.r + ',' + color.g + ',' + color.b + ')';
}

export function bar_chart_plotter(e: plotter_event): void {
  const ctx = e.drawingContext;
  const points = e.points;
  const y_bottom = e.dygraph.toDomYCoord(
    0,
    e.axis.g.attributes_.series_[e.setName].options.axis === 'y1' ? 0 : 1
  );

  ctx.fillStyle = darkenColor(e.color)
    .replace(/rgb/i, 'rgba')
    .replace(/\)/i, ',0.3)');

  if (e.color)
    ctx.strokeStyle = e.color.replace(/rgb/i, 'rgba').replace(/\)/i, ',0.6)');

  let min_sep = Infinity;
  for (let i = 1; i < points.length; i++) {
    const sep = points[i].canvasx - points[i - 1].canvasx;
    if (sep < min_sep) min_sep = sep;
  }
  const bar_width = Math.floor((2.0 / 3) * min_sep);

  for (let i = 0; i < points.length; i++) {
    const p = points[i];
    const center_x = p.canvasx;

    ctx.fillRect(
      center_x - bar_width / 2,
      p.canvasy,
      bar_width,
      y_bottom - p.canvasy
    );
    ctx.strokeRect(
      center_x - bar_width / 2,
      p.canvasy,
      bar_width,
      y_bottom - p.canvasy
    );
  }
}

export function legend_formatter(data: dygraphs.LegendData): string {
  if (data.x === undefined || data.x === 0)
    return '<div style="border: 1px solid black; border-radius: 2px; padding: 10px;" class="dygraph-legend">no data</div>';

  const bufer_station_order: Record<string, Array<Record<string, string>>> = {};
  for (const serie of data.series) {
    if (Number.isNaN(serie.y)) continue;
    const separator = serie.labelHTML.lastIndexOf('_');
    const aux_separator = serie.labelHTML.lastIndexOf('!');
    const station = serie.labelHTML.slice(0, separator);
    const observation = serie.labelHTML.slice(separator + 1, aux_separator);
    const aux = Number(
      serie.labelHTML.slice(aux_separator + 1, serie.labelHTML.length)
    );

    const observation_obj = store.state.observations.find(
      (observation_obj: Observation) =>
        observation_obj.labels.en === observation
    );
    const unit =
      observation_obj === undefined
        ? new Unit(0, '', '')
        : store.state.units.find(
          (unit_obj: Unit) => unit_obj.code === observation_obj.link_unit
        ) ?? new Unit(0, '', '');

    if (!(station in bufer_station_order)) bufer_station_order[station] = [];
    bufer_station_order[station].push({
      obs:
        observation +
        (aux > 0 ? ' aux' + aux : '') +
        ': ' +
        serie.y.toString() +
        ' ' +
        (serie.y.toString() === '/' ? '' : unit.label),
      color: serie.color,
    });
  }

  const line_legend = _.keys(bufer_station_order)
    .map(
      key =>
        `${key}: <br/>${bufer_station_order[key]
          .map(x => `<span style="color: ${x.color}">- ${x.obs}</span><br/>`)
          .join('')}`
    )
    .join('');

  return `<div style="border: 1px solid black; border-radius: 2px; padding: 10px;">${data.xHTML}<br/>${line_legend}</div>`;
}
