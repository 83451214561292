export class DataLogger {
  code: number;
  identifier: string;
  hardware_aux_to_software_aux: Record<string, Record<string, number>>;
  station: number;
  wind_aux0?: number;
  wind_aux1?: number;
  wind_aux2?: number;

  constructor(
    code: number,
    identifier: string,
    hardware_aux_to_software_aux: Record<string, Record<string, number>>,
    station: number,
    wind_aux0?: number,
    wind_aux1?: number,
    wind_aux2?: number
  ) {
    this.code = code;
    this.identifier = identifier;
    this.hardware_aux_to_software_aux = hardware_aux_to_software_aux;
    this.station = station;
    this.wind_aux0 = wind_aux0;
    this.wind_aux1 = wind_aux1;
    this.wind_aux2 = wind_aux2;
  }
}

export interface data_logger_i {
  code: number;
  identifier: string;
  hardware_aux_to_software_aux: Record<string, Record<string, number>>;
  station: number;
  config_aux0_wind?: number;
  config_aux1_wind?: number;
  config_aux2_wind?: number;
}
