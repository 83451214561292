// import interfaces
import moment, { Moment } from 'moment-timezone';

export class LogCommunication {
  st: number;
  d: Moment;
  s: string;
  t: string;
  i: number;
  e: number;
  u: number;
  fd: Moment;
  ld: Moment;
  o: Array<number>;
  err: Array<string>;

  constructor(
    st = NaN,
    d = '',
    s = '',
    t = '',
    i = 0,
    e = 0,
    u = 0,
    fd = '',
    ld = '',
    o: Array<number> = [],
    err: Array<string> = [],
    timezone = 'UTC'
  ) {
    this.st = st;
    this.d = moment.tz(d, timezone);
    this.s = s;
    this.t = t;
    this.i = i;
    this.e = e;
    this.u = u;
    this.fd = moment.tz(fd, timezone);
    this.ld = moment.tz(ld, timezone);
    this.o = o;
    this.err = err;
  }
}

export interface log_communication_i {
  id: string;
  st: number;
  d: string;
  s: string;
  t: string;
  i: number;
  e: number;
  u: number;
  fd: string;
  ld: string;
  o: Array<number>;
  err: Array<string>;
}