import script from "./comm_widget_cell.vue?vue&type=script&setup=true&lang=ts"
export * from "./comm_widget_cell.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QTooltip,QMenu,QCard,QCardSection});
