export class Station {
  code: number;
  name: string;
  exist_observation: Array<number>;
  import_observation: Array<number>;
  query_observation: Array<number>;
  data_logger: Array<number>;
  coordinate: {
    latitude: number;
    longitude: number;
    altitude: number;
    aero_altitude?: number;
  };

  constructor(
    code: number,
    name: string,
    exist_observation: Array<number>,
    import_observation: Array<number>,
    query_observation: Array<number>,
    data_logger: Array<number>,
    coordinate: {
      latitude: number;
      longitude: number;
      altitude: number;
      aero_altitude?: number;
    }
  ) {
    this.code = code;
    this.name = name;
    this.exist_observation = exist_observation;
    this.import_observation = import_observation;
    this.query_observation = query_observation;
    this.data_logger = data_logger;
    this.coordinate = coordinate;
  }
}

export interface station_i {
  code: number;
  name: string;
  exist_observation: Array<number>;
  import_observation: Array<number>;
  query_observation: Array<number>;
  data_logger: Array<number>;
  coordinate: {
    latitude: number;
    longitude: number;
    altitude: number;
    aero_altitude?: number;
  };
}
