import script from "./table_page.vue?vue&type=script&setup=true&lang=ts"
export * from "./table_page.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QToolbar,QSeparator,QTooltip,QRadio,QBtn,QInnerLoading});
