import script from "./home_page.vue?vue&type=script&setup=true&lang=ts"
export * from "./home_page.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QBtn,QTooltip});
