// import classes
import {
  ObservationSorterItem,
  ObservationSorterItemLeaf,
  ObservationSorterItemNode,
  observation_sorter_item_i,
} from '@/models/observation_sorter/observation_sorter_item';

export class ObservationSorter {
  link_granularity: number;
  observations: Array<ObservationSorterItem>;

  constructor(
    link_granularity: number,
    observations: Array<observation_sorter_item_i>
  ) {
    this.link_granularity = link_granularity;
    this.observations = observations.map(observation_sorter_item =>
      'observation' in observation_sorter_item
        ? new ObservationSorterItemLeaf(
          observation_sorter_item.labels,
          observation_sorter_item.observation as { code: number; aux: number; }
        )
        : new ObservationSorterItemNode(
          observation_sorter_item.labels,
          observation_sorter_item.contained_observations as Array<number>,
          observation_sorter_item.observations as Array<observation_sorter_item_i>
        )
    );
  }
}

export interface observation_sorter_i {
  link_granularity: number;
  observations: Array<observation_sorter_item_i>;
}
