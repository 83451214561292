// import interfaces
import moment, { Moment } from 'moment-timezone';

// import store
import store from '@/store';

export class DataSingle {
  date: Moment;
  value: number | string | Moment | null | undefined;

  constructor(
    timezone = 'Europe/Rome',
    value: number | string | null | undefined = undefined,
    date = moment.tz(timezone).toISOString(),
    observation = NaN,
  ) {
    this.date = moment.tz(date, timezone);

    if (typeof value === 'string') {
      if (store.state.observations.find(o => o.code === observation)?.type === 'date')
        this.value = moment.tz(value.trim(), timezone);
      else this.value = value.trim();
    } else this.value = value;
  }
}
