export class ObservationValue {
  code: number;
  aux: number;

  constructor(code: number, aux: number) {
    this.code = code;
    this.aux = aux;
  }

  toJson(): observation_value_i {
    return {
      code: this.code,
      aux: this.aux,
    };
  }
}

export class ObservationLabel extends ObservationValue {
  labels: Record<string, string>;

  constructor(code: number, aux: number, labels: Record<string, string>) {
    super(code, aux);

    this.labels = labels;
  }
}

export class ObservationValueGraph extends ObservationValue {
  histogram: boolean;
  secondary_axis: boolean;

  constructor(
    code: number,
    aux: number,
    histogram = false,
    secondary_axis = false
  ) {
    super(code, aux);

    this.histogram = histogram;
    this.secondary_axis = secondary_axis;
  }

  toCompactedJson(): observation_value_i {
    return {
      code: this.code,
      aux: this.aux,
    };
  }

  toJson(): observation_value_i {
    return {
      code: this.code,
      aux: this.aux,
      histogram: this.histogram,
      secondary_axis: this.secondary_axis,
    };
  }
}

export interface observation_value_i {
  code: number;
  aux: number;
  histogram?: boolean;
  secondary_axis?: boolean;
}
