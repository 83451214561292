// import classes
import {
  ObservationValue,
  observation_value_i,
} from '@/models/data_context/observation_value';

export abstract class ObservationSorterItem {
  labels: Record<string, string>;

  constructor(labels: Record<string, string>) {
    this.labels = labels;
  }
}

export class ObservationSorterItemNode extends ObservationSorterItem {
  observations: Array<ObservationSorterItem>;
  contained_observations: Array<number>;

  constructor(
    labels: Record<string, string>,
    contained_observations: Array<number>,
    observations: Array<observation_sorter_item_i>
  ) {
    super(labels);

    this.contained_observations = contained_observations;
    this.observations = observations.map(observation_sorter_item =>
      'observation' in observation_sorter_item
        ? new ObservationSorterItemLeaf(
          observation_sorter_item.labels,
          observation_sorter_item.observation as { code: number; aux: number; }
        )
        : new ObservationSorterItemNode(
          observation_sorter_item.labels,
          observation_sorter_item.contained_observations as Array<number>,
          observation_sorter_item.observations as Array<observation_sorter_item_i>
        )
    );
  }
}

export class ObservationSorterItemLeaf extends ObservationSorterItem {
  observation: ObservationValue;

  constructor(
    labels: Record<string, string>,
    observation: { code: number; aux: number; }
  ) {
    super(labels);

    this.observation = new ObservationValue(observation.code, observation.aux);
  }
}

export interface observation_sorter_item_i {
  labels: Record<string, string>;
  observations?: Array<observation_sorter_item_i>;
  contained_observations?: Array<number>;
  observation?: observation_value_i;
}
