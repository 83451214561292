// import interfaces
import { observation_value_i } from '@/models/data_context/observation_value';
import { depth_i } from '@/models/data_context/depth';

// import classes
import {
  ObservationValue,
  ObservationValueGraph,
} from '@/models/data_context/observation_value';
import { Depth } from '@/models/data_context/depth';

// import env vars
import { DEFAULT_GRANULARITY } from '@/env_var';

abstract class WidgetParam {
  label: string;
  station_label?: boolean;
  show_date: boolean;
  show_station: boolean;
  show_obs: boolean;

  constructor(
    label: string,
    station_label: boolean,
    show_date: boolean,
    show_station: boolean,
    show_obs: boolean
  ) {
    this.label = label;
    this.station_label = station_label;
    this.show_date = show_date;
    this.show_station = show_station;
    this.show_obs = show_obs;
  }

  abstract toJson(): widget_param_i;
}

export class WidgetParamSingle extends WidgetParam {
  station: number;
  granularity: number;
  observation: ObservationValue;

  constructor(
    label: string,
    station_label: boolean,
    show_date: boolean,
    show_station: boolean,
    show_obs: boolean,
    station: number,
    granularity: number,
    observation: observation_value_i
  ) {
    super(label, station_label, show_date, show_station, show_obs);

    this.station = station;
    this.granularity =
      granularity ??
      (observation.code === -1
        ? DEFAULT_GRANULARITY
        : Number(observation.code.toString().slice(0, 2)));
    this.observation = new ObservationValue(observation.code, observation.aux);
  }

  toJson(): widget_param_i {
    return {
      label: this.label,
      station_label: this.station_label,
      show_date: this.show_date,
      show_station: this.show_station,
      show_obs: this.show_obs,
      station: this.station,
      observation: this.observation.toJson(),
    };
  }
}

export class WidgetParamMultiple extends WidgetParam {
  station: Array<number>;
  granularity: number;
  observation: Array<ObservationValueGraph>;
  depth: Depth;

  constructor(
    label: string,
    station_label: boolean,
    show_date: boolean,
    show_station: boolean,
    show_obs: boolean,
    station: Array<number>,
    granularity: number,
    observation: Array<observation_value_i>,
    depth: depth_i
  ) {
    super(label, station_label, show_date, show_station, show_obs);

    this.station = station;
    this.granularity =
      granularity ??
      (observation.length > 0
        ? Number(observation[0].code.toString().slice(0, 2))
        : DEFAULT_GRANULARITY);
    this.observation = observation.map(
      (observation_value: observation_value_i) =>
        new ObservationValueGraph(
          observation_value.code,
          observation_value.aux,
          observation_value.histogram,
          observation_value.secondary_axis
        )
    );
    this.depth = new Depth(depth.number, depth.unit);
  }

  toJson(): widget_param_i {
    return {
      label: this.label,
      station_label: this.station_label,
      show_date: this.show_date,
      show_station: this.show_station,
      show_obs: this.show_obs,
      station: this.station,
      observation: this.observation.map(
        (observation_value: ObservationValueGraph) => observation_value.toJson()
      ),
      depth: this.depth.toJson(),
    };
  }
}

export class WidgetParamLog extends WidgetParam {
  station: Array<number>;
  depth: Depth;

  constructor(
    label: string,
    station_label: boolean,
    show_date: boolean,
    show_station: boolean,
    show_obs: boolean,
    station: Array<number>,
    depth: depth_i
  ) {
    super(label, station_label, show_date, show_station, show_obs);

    this.station = station;
    this.depth = new Depth(depth.number, depth.unit);
  }

  toJson(): widget_param_i {
    return {
      label: this.label,
      station_label: this.station_label,
      show_date: this.show_date,
      show_station: this.show_station,
      show_obs: this.show_obs,
      station: this.station,
      depth: this.depth.toJson(),
    };
  }
}

export interface widget_param_i {
  label: string;
  station_label?: boolean;
  show_date: boolean;
  show_station: boolean;
  show_obs: boolean;
  station: number | Array<number>;
  granularity?: number;
  observation?: observation_value_i | Array<observation_value_i>;
  depth?: depth_i;
}
