// import interfaces
import moment, { Moment } from 'moment-timezone';

export class LogTechnical {
  st: number;
  d: Moment;
  grav: string;
  task: string;
  cat: string;
  msg: string;
  type: string | null;

  constructor(
    st: number,
    d: string,
    grav: string,
    task: string,
    cat: string,
    msg: string,
    type: string | null,
    timezone = 'UTC'
  ) {
    this.st = st;
    this.d = moment.tz(d, timezone);
    this.grav = grav;
    this.task = task;
    this.cat = cat;
    this.msg = msg;
    this.type = type;
  }
}

export interface log_technical_i {
  st: number;
  d: string;
  grav: string;
  task: string;
  cat: string;
  msg: string;
  type: string | null;
}
